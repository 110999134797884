<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        color="color3"
        text
        v-on="on"
        x-small
        :loading="loading"
      >{{btnText || 'Print'}}</v-btn>
    </template>
    <v-card>
      <v-toolbar color="color2 color2Text--text">
        <v-toolbar-title>Download QR Codes</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="color1 color1Text--text"
          small fab
          @click.stop="dialog = false"
          :disabled="loading"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-2">
        <v-window v-model="window">
          <v-window-item :key="0">
            <div>
              This will create generic QR codes for the given courts. When the QR code is scanned it will find the event that is happening at the time it is scanned and send then user to the court at that event.
            </div>
            <div class="text-end">
              <v-btn
                color="color3"
                x-small text
                @click.stop="window = 1"
              >
                Add Courts
              </v-btn>
            </div>
            <v-checkbox
              v-for="(p, i) in pp"
              :key='`${p.title}${i}`'
              :label="p.desc || p.title"
              v-model="p.selected"
              hide-details dense
              color="success"
            ></v-checkbox>
          </v-window-item>
          <v-window-item :key="1">
            <add-courts
              :flat="true"
              :emit="true"
              @emit-courts="saveCourts"
            ></add-courts>
          </v-window-item>
        </v-window>
      </v-card-text>
      <v-divider v-if="window === 0"></v-divider>
      <v-card-actions class="justify-center" v-if="window === 0">
        <v-btn
          color="success"
          :loading="loading"
          @click.stop="download"
          :disabled="this.downloadable.length === 0"
        >download ({{this.downloadable.length}})</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AddCourts from '@/components/Tournament/Courts/AddCourts'
import { qrCodeBase } from '@/classes/_URL'
const pdfMake = () => import('pdfmake/build/pdfmake.js')
const pdfFonts = () => import('pdfmake/build/vfs_fonts.js')

export default {
  props: ['btnText', 'name', 'organization'],
  data () {
    return {
      dd: null,
      loading: false,
      dialog: false,
      pp: [],
      window: 0
    }
  },
  computed: {
    downloadable () {
      return this.pp.filter(f => f.selected)
    }
  },
  methods: {
    init () {
      this.window = 0
      this.pp = []
    },
    saveCourts (data) {
      data.forEach(c => {
        const x = this.pp.find(f => f.title === `Court ${c}`)
        if (x) {
          x.selected = true
        } else {
          this.pp.push({
            title: `Court ${c}`,
            subtitle: this.organization.name,
            url: `${qrCodeBase}&url=https://${this.organization.username}.volleyballlife.com/court/${c}`,
            url2: `https://${this.organization.username}.volleyballlife.com/court/${c}`,
            selected: true
          })
        }
      })
      this.window = 0
    },
    createContent () {
      this.dd = {
        content: [],
        images: {
          scanMe: 'https://vblimages.blob.core.windows.net/images/152184d7-12af-4207-9b77-6c394945b655.png'
        },
        styles: {
          title: {
            fontSize: 33,
            bold: true,
            alignment: 'center'
          },
          subtitle: {
            fontSize: 22,
            bold: true,
            alignment: 'center',
            color: '#A6A6A6'
          },
          url: {
            fontSize: 12,
            bold: false,
            alignment: 'center',
            color: '#EEEEEE'
          }
        }
      }
      this.downloadable.filter(f => f.selected).forEach((p, i) => {
        const imageName = `image${i}`
        this.dd.content.push({ style: 'title', text: p.title })
        this.dd.content.push({ style: 'subtitle', text: p.subtitle, margin: [0, 0, 0, 0] })
        this.dd.content.push({ style: 'url', text: p.url2, margin: [0, 0, 0, 0] })
        this.dd.images[imageName] = p.url
        this.dd.content.push({ image: imageName, width: 500, style: 'title' })
        var lastImage = { image: 'scanMe', width: 500, style: 'title' }
        if (i + 1 < this.downloadable.length) lastImage.pageBreak = 'after'
        this.dd.content.push(lastImage)
      })
    },
    download () {
      this.loading = true
      this.createContent()
      Promise.all([pdfMake(), pdfFonts()])
        .then(data => {
          const p = data[0]
          const f = data[1]
          window.pdfMake.vfs = f.pdfMake.vfs

          const n = this.name || 'QR Codes'
          p.createPdf(this.dd).download(n, () => {
            this.loading = false
            this.dialog = false
          })
        })
    },
    onSavingChange (v) {
      this.loading = v
    }
  },
  watch: {
    dialog: 'init'
  },
  components: {
    AddCourts
  }
}
</script>
